import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../profile/profile.component';
import { Observable, Subscriber } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
// import { firestore } from 'firebase/app';
// import { FieldValue } from '@firebase/firestore-types';
import { observeOn } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { deleteField } from 'firebase/firestore';

export interface ScheduleItem {
  name: string;
  type: string;
  source?: string;
  repeat: object;
  time: Date;
  active: boolean;
  hour: number;
  minute: number;
  responseRequired: boolean;
  message: string;
  useCustomMessage: boolean;
}

export interface Minute {
  value: number;
  viewValue: string;
}
export interface Hour {
  value: number;
  viewValue: string;
}

export interface SelectedDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss'],
})
export class SchedulingComponent implements OnInit {
  env = environment;
  userId: string;
  scheduleName: string;
  scheduleType: string;
  scheduleNameControl = new FormControl();
  fromControlHour = new FormControl();
  hour: string[] = [];
  selectedTime: any;
  selectedHour: any;
  selectedMinute: any;
  selectedDays: any;
  daySelect: any;
  scheduleActive: boolean;
  itemId: string;
  editItem: boolean;
  active: boolean;
  activesch: boolean;
  fromControlMinute = new FormControl();
  minute: string[] = [];
  panelOpenState: boolean;

  responseRequired: boolean;

  mobileDevice: boolean;
  userName: string;
  myForm: FormGroup;

  useCustomMessage: boolean;
  message: string;
  // customMessage: string;
  constructor(
    public dialogRef: MatDialogRef<SchedulingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public afs: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.determineDevice();
    console.log(this.data.editactive);
    if (data.editactive === undefined) {
      this.scheduleActive = true;
    } else {
      this.scheduleActive = data.editactive;
    }

    this.fromControlMinute = new FormControl('', [
      Validators.max(60),
      Validators.min(0),
    ]);
    this.fromControlHour = new FormControl('', [
      Validators.max(23),
      Validators.min(0),
    ]);
    console.log('this.userName', this.userName);
    if (data.infoScheduleActive === undefined) {
      this.panelOpenState = true;
    } else {
      this.panelOpenState = data.infoScheduleActive;
    }
    if (data.editId) {
      this.editItem = true;
      this.itemId = data.editId;
      this.activesch = data.active;
      console.log('activesch', this.activesch);
      this.scheduleName = data.editName;
      this.scheduleType = data.editType;
      this.selectedTime =
        data.editTime.getHours() + ':' + data.editTime.getMinutes();
      // this.selectedHour = data.editTime.getHours();
      // this.selectedMinute = data.editTime.getMinutes();
      this.daySelect = data.editRepeat;
      this.responseRequired = data.editResponseRequired;
      this.useCustomMessage = data.editCustomMessage;
      this.message = data.editMessage;
      console.log('time', this.selectedTime);
      console.log('repeat', this.daySelect);
    } else {
      if (this.env.env === 'helperapp') {
        this.scheduleType = 'reminder';
      } else {
        this.scheduleType = 'source';
      }
      this.useCustomMessage = false;
      this.daySelect = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      };
      if (!this.responseRequired) {
        this.responseRequired = true;
      }
    }
  }

  ngOnInit() {
    console.log('this.data', this.data);
    this.userId = this.data.userId;
    this.userName = this.data.userName;
    this.message = `Hallo ${this.userName}, ik herinner je aan: ... Lukt dat?`;

    this.scheduleNameControl.valueChanges.subscribe((value) => {
      // this.scheduleName = value;
      console.log('before if', this.message);

      if (value === undefined) {
        this.message = `Hallo ${this.userName}, ik herinner je aan: ... Lukt dat?`;
      } else {
        if (!this.useCustomMessage) {
          this.message = `Hallo ${this.userName}, ik herinner je aan: ${value}.`;
          if (this.responseRequired) {
            this.message = this.message + ' Lukt dat?';
          }
        }
      }
      console.log('after if', this.message);
    });
  }
  changeState() {
    const userDoc = this.afs.doc('users/' + this.userId);
    if (this.panelOpenState) {
      this.panelOpenState = false;
      userDoc.update({
        infoScheduleActive: this.panelOpenState,
      });
    } else if (!this.panelOpenState) {
      this.panelOpenState = true;
      userDoc.update({
        infoScheduleActive: this.panelOpenState,
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  checkCustomMessage(e) {
    console.log('e', e);
    // if (!e.checked && !this.responseRequired) {
    //   this.message = 'Ik moest je helpen herinneren aan ' + this.scheduleName;
    // }
    if (!e.checked && this.responseRequired) {
      this.message = `Hallo ${this.userName}, ik herinner je aan: ${this.scheduleName}. Lukt dat?`;
      this.useCustomMessage = e.checked;
    }
  }

  checkRequiredCustomMessage(e) {
    console.log('e', e);
    if (!e.checked && !this.useCustomMessage) {
      this.message = `Hallo ${this.userName}, ik herinner je aan: ${this.scheduleName}.`;
      // this.responseRequired = e.checked;
    } else if (!this.useCustomMessage) {
      this.message = `Hallo ${this.userName}, ik herinner je aan: ${this.scheduleName}. Lukt dat?`;
    }
  }

  async saveScheduler(chosenDays) {
    console.log('selectedTime', this.selectedTime);
    let time = this.selectedTime.split(':');
    console.log('time', time);
    console.log('message', this.message);
    console.log('this.useCustomMessage', this.useCustomMessage);
    this.selectedDays = {};
    const selectedTime = new Date();
    selectedTime.setFullYear(2020, 0, 5);
    selectedTime.setHours(time[0], time[1], 0, 0);
    console.log('Selected time =>', selectedTime);
    const schedulerCollection = this.afs.collection<ScheduleItem>(
      'users/' + this.userId + '/scheduleItems/'
    );
    const schedulerDoc = this.afs.doc(
      'users/' + this.userId + '/scheduleItems/' + this.itemId
    );

    console.log('Selected days => ', chosenDays);
    chosenDays.forEach((element) => {
      if (element === 'monday') {
        console.log('monday selected');
        this.selectedDays.monday = true;
        // return this.selectedDays;
      }
      if (element === 'tuesday') {
        console.log('tuesday selected');
        this.selectedDays.tuesday = true;
        // return this.selectedDays;
      }
      if (element === 'wednesday') {
        console.log('wednesday selected');
        this.selectedDays.wednesday = true;
        // return this.selectedDays;
      }
      if (element === 'thursday') {
        console.log('thursday selected');
        this.selectedDays.thursday = true;
        // return this.selectedDays;
      }
      if (element === 'friday') {
        console.log('friday selected');
        this.selectedDays.friday = true;
        // return this.selectedDays;
      }
      if (element === 'saturday') {
        console.log('saturday selected');
        this.selectedDays.saturday = true;
        // return this.selectedDays;
      }
      if (element === 'sunday') {
        console.log('sunday selected');
        this.selectedDays.sunday = true;
        // return this.selectedDays;
      }
    });
    // this.selectedDays.subscribe( x => {
    //   console.log('x', x);
    // })
    console.log(this.selectedDays);
    let scheduleData: any = {
      time: selectedTime,
      repeat: this.selectedDays,
      name: this.scheduleName,
      type: this.scheduleType,
      hour: selectedTime.getHours(),
      minute: selectedTime.getMinutes(),
      responseRequired: this.responseRequired,
      message: this.message,
      useCustomMessage: this.useCustomMessage,
      active: this.scheduleActive,
    };
    if (scheduleData.type === 'source') {
      scheduleData.source = 'hallobot';
    } else {
      scheduleData.source = deleteField();
    }
    if (this.editItem) {
      await schedulerDoc.update(scheduleData);
      this.snackBar.open('Gepland bericht is opgeslagen', 'X', {
        duration: 5000,
      });
    } else {
      const scheduleId = this.afs.createId();
      schedulerCollection.doc(scheduleId).set(scheduleData, { merge: true });
      this.snackBar.open('Gepland bericht is opgeslagen ', 'X', {
        duration: 5000,
      });
    }
    this.dialogRef.close();
    console.log('selectedDays =>', this.selectedDays);
  }

  determineDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
