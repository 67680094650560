import {
  Component,
  OnInit,
  Inject,
  Optional,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../profile.component';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@firebase/firestore-types';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';

import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-contacts',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactsComponent implements OnInit {
  contactDetails: FormGroup;
  contactName: string;
  contactDelay: number;
  contactPhone: string;

  numberControl: FormControl;
  phoneControl: FormControl;

  editContact: boolean;
  contact: any;
  oldContactPhone: string;
  validated: boolean;
  panelOpenState: boolean;

  userId: string;

  prefix: string = environment.phone.nl;

  mobileDevice: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ContactsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public db: AngularFirestore,
    private _snackBar: MatSnackBar
  ) {
    this.numberControl = new FormControl('', [
      Validators.max(100),
      Validators.min(0),
    ]);
    this.phoneControl = new FormControl('+316', [
      Validators.minLength(12),
      Validators.maxLength(12),
    ]);
    this.determineDevice();
    this.userId = data.userId;
    this.editContact = data.editContact;

    if (data.infoContactActive === undefined) {
      this.panelOpenState = true;
    } else {
      this.panelOpenState = data.infoContactActive;
    }
    if (data.contact) {
      this.contactName = data.contact.name;
      this.contactDelay = data.contact.delay;
      this.contactPhone = data.contact.phone;
      console.log('data.contactPhone', data.contactPhone);
    }

    if (!this.contactPhone) {
      this.contactPhone = this.prefix;
    }
    console.log('check user id', this.userId);
  }

  ngOnInit() {
    this.contactDetails = this.formBuilder.group({
      contactPhoneFb: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))s*[)]?[-s.]?[(]?[0-9]{1,3}[)]?([-s.]?[0-9]{3})([-s.]?[0-9]{3,4})'
          ),
        ]),
      ],
    });
  }

  async saveContact() {
    if (!this.contactDelay) {
      this.contactDelay = 0;
    }
    console.log('formcontrol', this.numberControl);
    console.log('lets update this user');
    console.log('edit?', this.editContact);
    console.log('lets update this user');
    const userDoc = this.db.doc('users/' + this.userId);
    var contact = {
      name: this.contactName,
      delay: this.contactDelay,
      phone: this.contactPhone,
    };

    var batch = this.db.firestore.batch();
    if (this.data.editContact && contact.phone !== this.data.contact.phone) {
      contact['validated'] = false;
      contact['inviteOpen'] = true;
      batch.delete(
        userDoc.collection('contacts').doc(this.data.contact.phone).ref
      );
      // await userDoc.collection('contacts').doc(contact.phone).delete();
    } else if (!this.data.editContact) {
      contact['validated'] = false;
      contact['inviteOpen'] = true;
    }
    console.log('Contact =>', contact);
    batch.set(userDoc.collection('contacts').doc(contact.phone).ref, contact, {
      merge: true,
    });
    // await userDoc.collection('contacts').doc(contact.phone).set(contact, { merge: true });
    await batch.commit();
    if (this.editContact) {
      this._snackBar.open('Contactpersoon aangepast', 'X', {
        duration: 5000,
      });
    } else {
      this._snackBar.open('Contactpersoon opgeslagen', 'X', {
        duration: 5000,
      });
    }
    console.log('Document set!');
    this.dialogRef.close('saved');
  }

  changeState() {
    const userDoc = this.db.doc('users/' + this.userId);
    if (this.panelOpenState) {
      this.panelOpenState = false;
      userDoc.update({
        infoContactActive: this.panelOpenState,
      }),
        { merge: true };
    } else if (!this.panelOpenState) {
      this.panelOpenState = true;
      userDoc.update({
        infoContactActive: this.panelOpenState,
      }),
        { merge: true };
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  determineDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
