import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './auth/login/login.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { ProfileComponent } from './profile/profile.component';

import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { QRCodeModule } from 'angularx-qrcode';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { ContactsComponent } from './profile/dialogs/contact/contact.component';
import { ReminderComponent } from './profile/dialogs/reminder/reminder/reminder.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';

import { RegistrationComponent } from './auth/registration/registration.component';
import { SchedulingComponent } from './profile/dialogs/scheduling/scheduling.component';
import { AboutComponent } from './profile/tabs/about/about.component';
import { EditProfileComponent } from './profile/dialogs/edit-profile/edit-profile.component';
import { FirstTimeDialogComponent } from './profile/dialogs/first-time-dialog/first-time-dialog.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { DeleteConfirmComponent } from './profile/dialogs/delete-confirm/delete-confirm.component';
import { NumberInputDirective } from './directive/number-input.directive';
import { InfoDialogComponent } from './profile/dialogs/info-dialog/info-dialog.component';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OrganisationComponent } from './profile/tabs/organisation/organisation.component';
const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    ProfileComponent,
    ContactsComponent,
    ReminderComponent,
    RegistrationComponent,
    SchedulingComponent,
    AboutComponent,
    EditProfileComponent,
    FirstTimeDialogComponent,
    AutofocusDirective,
    DeleteConfirmComponent,
    NumberInputDirective,
    InfoDialogComponent,
    OrganisationComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    NoopAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatTabsModule,
    MatCardModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    QRCodeModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatCardModule,
    MatGridListModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    AngularFireDatabaseModule,
    MatChipsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
