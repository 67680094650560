import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../profile/profile.component';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@firebase/firestore-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  userForm: FormGroup;
  userId: string;
  // userFirstName: string;
  // userPhone: string;
  prefix: string = environment.phone.nl;
  panelOpenState: boolean;

  mobileDevice: boolean;
  constructor(
    public dialogRef: MatDialogRef<EditProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public afs: AngularFirestore,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.determineDevice();
    this.userId = data.userId;
    console.log('data.infoUserActive', data.infoUserActive);
    if (data.infoUserActive === undefined) {
      this.panelOpenState = true;
    } else {
      this.panelOpenState = data.infoUserActive;
    }
    console.log('panelOpenState', this.panelOpenState);
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(15)]],
      phone: ['+316'],
    });
    this.userForm.controls['phone'].disable();
    this.userForm.patchValue({
      firstName: this.data.userName,
      phone: this.data.userPhone,
    });
  }

  save() {
    console.log('userform.value', this.userForm.value);
    const userDoc = this.afs.doc('users/' + this.userId);
    userDoc.update(this.userForm.value);
    this.dialogRef.close();
    this._snackBar.open('Persoonlijke gegevens aangepast', 'X', {
      duration: 5000,
    });
  }

  changeState() {
    const userDoc = this.afs.doc('users/' + this.userId);
    if (this.panelOpenState) {
      this.panelOpenState = false;
      userDoc.update({
        infoUserActive: this.panelOpenState,
      });
    } else if (!this.panelOpenState) {
      this.panelOpenState = true;
      userDoc.update({
        infoUserActive: this.panelOpenState,
      });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  determineDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
