import {
  Component,
  OnInit,
  Injectable,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { WindowService } from '../../window.service';
import * as firebase from 'firebase/compat';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { environment } from '../../../environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  // @ViewChild('verifyInput', {static: false}) verifyInput;
  @ViewChild('verifyInput', { static: false }) verifyInput: ElementRef;
  video: string = environment.login.video;
  name: string = environment.name;
  prefix: string = environment.phone.nl;
  // loginMethodePhone = environment.loginMethode.phone;
  // loginMethodeEmail = environment.loginMethode.email;
  // @Output() messageToEmit = new EventEmitter<string>();
  phone: string;
  windowRef: any;
  loginCode: boolean;
  verificationCode: string;
  phoneControl: FormControl;
  errorLoginMessage: string;
  email: string;
  password: string;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();

  user: any;

  constructor(
    private win: WindowService,
    public afAuth: AngularFireAuth,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.phone = this.prefix;
    this.phoneControl = new FormControl('+316', [
      Validators.minLength(12),
      Validators.maxLength(12),
    ]);
    // console.log("Login methode => ", this.loginMethod)
  }
  // Login with SMS
  sendLoginCode() {
    // this.phone = "+316" + this.phone;
    console.log('User phone =>', this.phone);
    this.loginCode = true;
    const appVerifier = this.windowRef.recaptchaVerifier;

    const num = this.phone;

    this.afAuth
      .signInWithPhoneNumber(num, appVerifier)
      .then((result) => {
        this.windowRef.confirmationResult = result;
        try {
          console.log('verifyInput', this.verifyInput);
          this.verifyInput.nativeElement.focus();
        } catch (err) {
          console.error(err);
        }
      })
      .catch((error) => console.log(error));
  }

  async verifyLoginCode() {
    await this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then((result) => {
        this.user = result.user;
        console.log('user =>', this.user);
        // this.id = this.user.uid;
        // console.log('this.id',this.id);
        this.router.navigate(['/profile'], {});
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        this.errorLoginMessage =
          'Er is een incorrecte verificatiecode ingevuld';
        console.log(errorCode, errorMessage);
        // this._snackBar.open('Er is een incorrecte verificatiecode ingevuld', 'X', {
        //   duration: 5000,
        // });
      });
  }

  // Login with Email

  mailLogin() {
    this.afAuth
      .signInWithEmailAndPassword(this.email, this.password)
      .then(() => {
        this.router.navigate(['/profile'], {});
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // this._snackBar.open('Email en/of wachtwoord is incorrect ingevuld', 'X', {
        //   duration: 5000,
        // });
        // ...
      });
  }
  ngOnInit() {
    console.log('on init', this.verifyInput);
    this.loginCode = false;
    //firebase.auth().languageCode = 'nl';
    this.windowRef = this.win.windowRef;
    // console.log('phone', this.loginMethodePhone);
    // if (this.loginMethodePhone == true){
    // console.log('login methode', this.loginMethodePhone);
    // });
    // }
  }
  ngAfterViewInit() {
    console.log('on after view init', this.verifyInput);
    // setTimeout(function() {
    this.windowRef.recaptchaVerifier = new auth.RecaptchaVerifier(
      'recaptcha-container',
      { size: 'invisible' },
      auth.getAuth()
    );
    this.windowRef.recaptchaVerifier.render();
    // this.windowRef.recaptchaVerifier.verify();
    // this returns null
  }
}
